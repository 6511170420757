
import { computed, defineComponent, reactive, ref } from "vue";
import InputSearch from "../../../../../components/inputs/inputSearch/InputSearch.vue";
import CustomButton from "../../../../../components/inputs/customButton/CustomButton.vue";
import IconPlus from "../../../../../components/icons/IconPlus.vue";
import PolicyContainer from "../../../containers/DataContainer.vue";
import AddStateModal from "./modals/addGuidelineModal.vue";
import DeleteModal from "../../../modals/ConfirmDeleteModal.vue";
import { storeToRefs } from "pinia";
import { useBaseDataStore } from "@/store/settings/baseDataStore";
import { BaseDataInterface } from "@/store/settings/interfaces";

export default defineComponent({
  name: "baseDataState",
  components: {
    InputSearch,
    CustomButton,
    IconPlus,
    PolicyContainer,
    AddStateModal,
    DeleteModal,
  },
  setup() {
    const { states } = storeToRefs(useBaseDataStore());
    const openAddState = ref<boolean>(false);
    const openDeleteModal = ref<boolean>(false);
    const stateSearch = ref<string>("");
    const toEditData = ref<BaseDataInterface>({id:0});
    const state = reactive({});

    const filteredStates = computed(() => {
      return states.value.filter(
        (fitlerState: BaseDataInterface) =>
          fitlerState.name
            ?.toLowerCase()
            .includes(stateSearch.value.toLowerCase()) ||
          fitlerState.short_name
            ?.toLowerCase()
            .includes(stateSearch.value.toLowerCase())
      );
    });
    return {
      state,
      openAddState,
      openDeleteModal,
      toEditData,
      filteredStates,
      stateSearch,
    };
  },
});
