import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_policies = _resolveComponent("policies")!
  const _component_state = _resolveComponent("state")!
  const _component_types = _resolveComponent("types")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.name == 'SettingsBaseData')
      ? (_openBlock(), _createBlock(_component_policies, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$route.name == 'SettingsBaseDataStates')
      ? (_openBlock(), _createBlock(_component_state, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.$route.name == 'SettingsBaseDataTypes')
      ? (_openBlock(), _createBlock(_component_types, { key: 2 }))
      : _createCommentVNode("", true)
  ]))
}