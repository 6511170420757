import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "calendar",
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}
const _hoisted_2 = {
  id: "dots",
  transform: "translate(-714.1 -91.617)"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      id: "Rechteck_1791",
      "data-name": "Rechteck 1791",
      width: "24",
      height: "24",
      fill: "none"
    }, null, -1)),
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("circle", {
        id: "Ellipse_347",
        "data-name": "Ellipse 347",
        cx: "1.5",
        cy: "1.5",
        r: "1.5",
        transform: "translate(729.099 106.617)",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("circle", {
        id: "Ellipse_348",
        "data-name": "Ellipse 348",
        cx: "1.5",
        cy: "1.5",
        r: "1.5",
        transform: "translate(724.599 106.617)",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("circle", {
        id: "Ellipse_349",
        "data-name": "Ellipse 349",
        cx: "1.5",
        cy: "1.5",
        r: "1.5",
        transform: "translate(720.099 106.617)",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("circle", {
        id: "Ellipse_350",
        "data-name": "Ellipse 350",
        cx: "1.5",
        cy: "1.5",
        r: "1.5",
        transform: "translate(729.099 101.617)",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("circle", {
        id: "Ellipse_351",
        "data-name": "Ellipse 351",
        cx: "1.5",
        cy: "1.5",
        r: "1.5",
        transform: "translate(724.599 101.617)",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2),
      _createElementVNode("circle", {
        id: "Ellipse_352",
        "data-name": "Ellipse 352",
        cx: "1.5",
        cy: "1.5",
        r: "1.5",
        transform: "translate(720.099 101.617)",
        class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
      }, null, 2)
    ]),
    _createElementVNode("path", {
      id: "outline1",
      d: "M734.1,95.618h-3v-1a1,1,0,0,0-2,0v1h-6v-1a1,1,0,0,0-2,0v1h-3a2,2,0,0,0-2,2v14a2,2,0,0,0,2,2h16a2,2,0,0,0,2-2v-14A2,2,0,0,0,734.1,95.618Zm0,16h-16v-14h3v1a1,1,0,0,0,2,0v-1h6v1a1,1,0,0,0,2,0v-1h3Z",
      transform: "translate(-714.1 -91.617)",
      class: _normalizeClass(["transition-fill", 'fill-' + _ctx.color])
    }, null, 2)
  ]))
}