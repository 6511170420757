import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-primary mb-5" }
const _hoisted_2 = {
  key: 0,
  class: "grid grid-cols-2 gap-7"
}
const _hoisted_3 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "text-red ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_input_date = _resolveComponent("input-date")!
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_centered_modal = _resolveComponent("centered-modal")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_centered_modal, {
        key: 0,
        onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('close'))),
        title: 
            _ctx.baseDataType == 'policy'
                ? _ctx.editData && _ctx.editData.id
                    ? _ctx.$t('settings.savePolicy')
                    : _ctx.$t('settings.addPolicy')
                : _ctx.editData && _ctx.editData.id
                ? _ctx.$t('settings.saveType')
                : _ctx.$t('settings.addType')
        ,
        cancelButtonText: _ctx.$t('cancel'),
        mainContainerId: 'settings_container'
      }, {
        actionButton: _withCtx(() => [
          _createVNode(_component_custom_button, {
            id: 'save-button',
            class: "max-w-full",
            isDisabled: !_ctx.isAllFilled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createBD()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.editData && _ctx.editData.id ? _ctx.$t("save") : _ctx.$t("apply")), 1)
            ]),
            _: 1
          }, 8, ["isDisabled"])
        ]),
        content: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.baseDataType == "policy"
                        ? _ctx.$t("settings.policy")
                        : _ctx.baseDataType == "type"
                        ? _ctx.$t("settings.type")
                        : _ctx.$t("settings.state")), 1),
          _createVNode(_component_input_default, {
            id: 'name',
            name: 'name',
            modelValue: _ctx.toEditData.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toEditData.name) = $event)),
            type: 'text',
            label: _ctx.$t('settings.title'),
            class: "w-full"
          }, null, 8, ["modelValue", "label"]),
          _createVNode(_component_input_default, {
            id: 'short_name',
            name: 'short_name',
            modelValue: _ctx.toEditData.short_name,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.toEditData.short_name) = $event)),
            type: 'text',
            label: _ctx.$t('settings.abbreviation'),
            class: "w-full"
          }, null, 8, ["modelValue", "label"]),
          (_ctx.baseDataType == 'policy')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_input_default, {
                  id: 'addition',
                  name: 'addition',
                  modelValue: _ctx.toEditData.addition,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.toEditData.addition) = $event)),
                  type: 'text',
                  label: _ctx.$t('settings.addition'),
                  class: "w-full"
                }, null, 8, ["modelValue", "label"]),
                _createVNode(_component_input_date, {
                  id: 'date',
                  name: 'date',
                  modelValue: _ctx.toEditData.date,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.toEditData.date) = $event)),
                  hasMeta: true,
                  label: _ctx.$t('settings.date')
                }, null, 8, ["modelValue", "label"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.toEditData.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_custom_button, {
                  id: 'delete-button',
                  class: "max-w-full",
                  isPlainButton: true,
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('delete')))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_icon_trash, { color: 'red' }),
                      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("delete_" + _ctx.baseDataType)), 1)
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title", "cancelButtonText"]))
    : _createCommentVNode("", true)
}