
import { computed, defineComponent, reactive, ref, watch } from "vue";
import CenteredModal from "@/components/layout/modals/CenteredModal.vue";
import CustomButton from "@/components/inputs/customButton/CustomButton.vue";
import InputDefault from "@/components/inputs/inputDefault/InputDefault.vue";
import InputDate from "@/components/inputs/inputDate/InputDate.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import { BaseDataEditInterface } from "@/store/settings/interfaces";
import { cloneData } from "@/common/cloneData";
import { useHelpersStore } from "@/store/helpers";
import { storeToRefs } from "pinia";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useBaseDataStore } from "@/store/settings/baseDataStore";

export default defineComponent({
    name: "AddPolicyModal",
    components: {
        CenteredModal,
        CustomButton,
        InputDefault,
        IconTrash,
        InputDate,
    },
    props: {
        editData: {
            type: Object as () => BaseDataEditInterface,
            required: true,
        },
        open: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["close", "delete"],
    setup(props, ctx) {
        const state = reactive({});
        const toEditData = ref<BaseDataEditInterface>({
            name: "",
            short_name: "",
        });
        const { createBaseData, updateBaseData } = useBaseDataStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const { baseDataType } = storeToRefs(useBaseDataStore());
        const isAllFilled = computed(() => {
            let isFilled = false;
            if (
                toEditData.value.name &&
                toEditData.value.short_name &&
                (baseDataType.value == "policy"
                    ? toEditData.value.addition && toEditData.value.date
                    : true)
            ) {
                isFilled = true;
            }
            return isFilled;
        });
        watch(
            () => props.editData,
            () => {
                //"clone" data so prop is not modified until saved
                toEditData.value = cloneData(props.editData);
            }
        );
        async function createBD(): Promise<void> {
            setLoadingId("save-button");
            if (props.editData && props.editData.id) {
                const response = await updateBaseData(toEditData.value);
                removeLoadingId("save-button");
                if (response?.status == 200) {
                    toastMessageText.value = [
                        {
                            type: "success" as ToastMessageType,
                            message:
                                baseDataType.value == "policy"
                                    ? "Richtlinie wurde erfolgreich aktualisiert."
                                    : "Art wurde erfolgreich aktualisiert.",
                        },
                    ];
                    ctx.emit("close");
                }
            } else {
                const response = await createBaseData(toEditData.value);
                removeLoadingId("save-button");
                if (response?.status == 200 || response?.status == 201) {
                    toastMessageText.value = [
                        {
                            type: "success" as ToastMessageType,
                            message:
                                baseDataType.value == "policy"
                                    ? "Richtlinie wurde erfolgreich angelegt."
                                    : "Art wurde erfolgreich angelegt.",
                        },
                    ];
                    ctx.emit("close");
                }
            }
        }
        return { state, isAllFilled, toEditData, createBD, baseDataType };
    },
});
