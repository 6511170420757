import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative group" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-row justify-start items-center mb-2.5 -mt-1"
}
const _hoisted_3 = { class: "ml-4 w-14 mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_calendar = _resolveComponent("icon-calendar")!
  const _component_date_picker = _resolveComponent("date-picker")!
  const _component_input_default = _resolveComponent("input-default")!
  const _component_icon_trash = _resolveComponent("icon-trash")!
  const _component_input_dropdown_wrapper = _resolveComponent("input-dropdown-wrapper")!
  const _component_meta_handler = _resolveComponent("meta-handler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobileDevice == false || _ctx.type == 'date-range')
      ? (_openBlock(), _createBlock(_component_input_dropdown_wrapper, {
          key: 0,
          id: _ctx.id,
          name: _ctx.name,
          ref: "dropdownWrapperElement",
          modelValue: _ctx.computedValue,
          isDisabled: _ctx.isDisabled,
          isRequired: _ctx.isRequired,
          isInputDisabled: true,
          label: _ctx.label,
          inputStyle: _ctx.inputStyle,
          dropdownPosition: _ctx.dropdownPosition,
          dropdownStyle: { ..._ctx.dropdownStyle, paddingClasses: 'p-0' },
          transitionDuration: _ctx.transitionDuration,
          hasMeta: false,
          inputMeta: _ctx.inputMeta,
          metaStyle: _ctx.inputStyle
        }, {
          append: _withCtx(() => [
            _createVNode(_component_icon_calendar)
          ]),
          content: _withCtx(() => [
            _createVNode(_component_date_picker, {
              locale: {
                        id: _ctx.currentLanguageCode,
                        masks: { weekdays: 'WWW' },
                    },
              modelValue: _ctx.newDate,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newDate) = $event)),
                _ctx.handleDateChange
              ],
              "min-date": _ctx.minDate,
              "max-date": _ctx.maxDate,
              "title-position": "left",
              "is-expanded": "",
              class: "custom-date-picker bg-!transparent border-!transparent",
              "is-range": _ctx.type == 'date-range'
            }, null, 8, ["locale", "modelValue", "min-date", "max-date", "onUpdate:modelValue", "is-range"]),
            (_ctx.type == 'datetime-local')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.timeLabel), 1),
                  _createVNode(_component_input_default, {
                    class: "w-20",
                    id: 'time' + _ctx.id,
                    name: 'time' + _ctx.id,
                    type: 'time',
                    isDisabled: _ctx.isDisabled,
                    modelValue: _ctx.newTime,
                    "onUpdate:modelValue": [
                      _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newTime) = $event)),
                      _ctx.handleTimeChange
                    ],
                    hasMeta: false,
                    inputMeta: _ctx.inputMeta,
                    metaStyle: _ctx.inputStyle,
                    inputStyle: _ctx.inputStyle,
                    onEnter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeDropDown()))
                  }, null, 8, ["id", "name", "isDisabled", "modelValue", "onUpdate:modelValue", "inputMeta", "metaStyle", "inputStyle"]),
                  _createVNode(_component_icon_trash, {
                    color: 'red',
                    class: "ml-auto mr-4 cursor-pointer",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => {
                            _ctx.$emit('update:modelValue', null);
                            _ctx.closeDropDown();
                        })
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["id", "name", "modelValue", "isDisabled", "isRequired", "label", "inputStyle", "dropdownPosition", "dropdownStyle", "transitionDuration", "inputMeta", "metaStyle"]))
      : (_openBlock(), _createBlock(_component_input_default, {
          key: 1,
          id: _ctx.id,
          name: _ctx.name,
          type: _ctx.type,
          modelValue: _ctx.modelValue,
          "onUpdate:modelValue": _ctx.handleMobileDateChange,
          isDisabled: _ctx.isDisabled,
          isRequired: _ctx.isRequired,
          label: _ctx.label,
          hasMeta: false,
          inputMeta: _ctx.inputMeta,
          metaStyle: _ctx.inputStyle,
          inputStyle: _ctx.inputStyle
        }, {
          append: _withCtx(() => [
            _createVNode(_component_icon_calendar)
          ]),
          _: 1
        }, 8, ["id", "name", "type", "modelValue", "onUpdate:modelValue", "isDisabled", "isRequired", "label", "inputMeta", "metaStyle", "inputStyle"])),
    (_ctx.hasMeta)
      ? (_openBlock(), _createBlock(_component_meta_handler, {
          key: 2,
          inputMeta: _ctx.metaInformation,
          metaStyle: _ctx.inputStyle
        }, null, 8, ["inputMeta", "metaStyle"]))
      : _createCommentVNode("", true)
  ]))
}