
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import InputSearch from "../../../../../components/inputs/inputSearch/InputSearch.vue";
import CustomButton from "../../../../../components/inputs/customButton/CustomButton.vue";
import IconPlus from "../../../../../components/icons/IconPlus.vue";
import PolicyContainer from "../../../containers/DataContainer.vue";
import AddPolicyModal from "./modals/addGuidelineModal.vue";
import DeleteModal from "../../../modals/ConfirmDeleteModal.vue";
import loadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { storeToRefs } from "pinia";
import {
    BaseDataEditInterface,
    BaseDataInterface,
    BaseDataTypeEnum,
} from "@/store/settings/interfaces";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useBaseDataStore } from "@/store/settings/baseDataStore";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "baseDataTypes",
    components: {
        InputSearch,
        CustomButton,
        IconPlus,
        PolicyContainer,
        AddPolicyModal,
        DeleteModal,
        loadingAnimation,
    },
    setup() {
        const { types, baseDataType } = storeToRefs(useBaseDataStore());
        const { fetchBaseDataTypes, deleteBaseData } = useBaseDataStore();
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const openAddType = ref<boolean>(false);
        const openDeleteModal = ref<boolean>(false);
        const typesSearch = ref<string>("");
        const toEditData = ref<BaseDataEditInterface>({ id: 0 });
        const state = reactive({});
        const router = useRouter();

        const filteredTypes = computed(() => {
            return types.value.filter(
                (type: BaseDataInterface) =>
                    type.name
                        ?.toLowerCase()
                        .includes(typesSearch.value.toLowerCase()) ||
                    type.short_name
                        ?.toLowerCase()
                        .includes(typesSearch.value.toLowerCase())
            );
        });
        async function deleteData(): Promise<void> {
            setLoadingId("confirm-delete-button");
            await deleteBaseData(toEditData.value);
            removeLoadingId("confirm-delete-button");
            toastMessageText.value = [
                {
                    type: "success" as ToastMessageType,
                    message: "Art wurde erfolgreich gelöscht.",
                },
            ];
            openDeleteModal.value = false;
            openAddType.value = false;
            toEditData.value = { id: 0 };
        }

        function openEdit(type: BaseDataInterface): void {
            toEditData.value = type;
            openAddType.value = true;
            baseDataType.value = BaseDataTypeEnum.TYPE;
        }

        function openAdd(): void {
            openAddType.value = true;
            baseDataType.value = BaseDataTypeEnum.TYPE;
        }
        const isLoading = ref(false);

        onMounted(async () => {
            types.value = [];
            isLoading.value = true;
            const response = await fetchBaseDataTypes();
            isLoading.value = false;
            if (response?.status != 200) {
                router.push({ name: "Settings" });
            }
        });
        return {
            state,
            filteredTypes,
            openAddType,
            openDeleteModal,
            toEditData,
            typesSearch,
            deleteData,
            types,
            openEdit,
            baseDataType,
            openAdd,
            isLoading,
        };
    },
});
