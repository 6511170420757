
import { DatePicker } from "v-calendar";
import MetaHandler from "../metaHandler/MetaHandler.vue";
import { useNavigatorOptions } from "../navigator_options";
import { computed, defineComponent, ref, watch } from "vue";
import { DateRangeInterface, InputStyleInterface } from "../input_interfaces";
import { InputMetaInterface } from "../metaHandler/meta_handler_interfaces";
import {
    DropdownPositionInterface,
    DropDownStyleInterface,
} from "../../helpers/dropDown/drop_down_interfaces";
import InputDropdownWrapper from "../inputDropdownWrapper/InputDropdownWrapper.vue";
import InputDefault from "../inputDefault/InputDefault.vue";
import IconCalendar from "../../icons/IconCalendar.vue";
import IconTrash from "../../icons/IconTrash.vue";
import { useHelpersStore } from "@/store/helpers";
import { MetaInformationInterface } from "@/store/helpers/interfaces";
import { storeToRefs } from "pinia";

export default defineComponent({
    name: "InputDate",
    components: {
        InputDropdownWrapper,
        InputDefault,
        IconCalendar,
        DatePicker,
        IconTrash,
        MetaHandler,
    },
    props: {
        /**
         * id is necessary to uniquely identify this input
         */
        id: {
            type: String,
            required: true,
        },
        /**
         * specifies the name of the HTML Input element
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * HTML Input type
         * only use: date, datetime-local, date-range
         */
        type: {
            type: String,
            default: "date",
            validator: (value: string) => {
                return ["date", "datetime-local", "date-range"].includes(value);
            },
        },
        /**
         * if it is a mobile device there should be the native date picker or
         * date time picker
         */
        disableMobileStyle: {
            type: Boolean,
            default: false,
        },
        /**
         * the min date to choose from
         */
        minDate: {
            type: Date,
            required: false,
        },
        /**
         *  the max date to choose from
         */
        maxDate: {
            type: Date,
            required: false,
        },

        /**
         * specifies the label of the HTML Input element
         */
        timeLabel: {
            type: String,
            default: "Zeit",
        },

        /**
         * displays ' *' behind the input label
         */
        isRequired: {
            type: Boolean,
            default: false,
        },
        /**
         *adds an overlay if it is disabled
         */
        isDisabled: {
            type: Boolean,
            default: false,
        },
        /**
         * the label of the input, if it is undefined the input is getting centered
         */
        label: {
            type: String,
            required: false,
        },
        /**
         * the displayed value of the input
         * format type datetime-local: yyyy-MM-ddTHH:mm 2020-01-31T12:30
         * format type date: yyyy-MM-dd 2020-01-31
         * format type date-range: {start:yyyy-MM-dd, end:yyyy-MM-dd}
         */
        modelValue: {
            type: [String, Object as () => DateRangeInterface],
        },

        /**
         * Duration in Milliseconds for opening transition
         */
        transitionDuration: {
            type: Number,
            default: 150,
        },

        /**
         * Configuration Object for dropdown Style with
         * following attributes:
         * {
         *      ! borderColor?: string;
         *      ! backgroundColor?: string;
         *      ! shadow?: string;
         *      ! widthClasses?: string;
         * }
         */
        dropdownStyle: {
            type: Object as () => DropDownStyleInterface,
            default: {} as DropDownStyleInterface,
        },

        /**
         * if this is set the input saves space for the bottom meta container and the message can get displayed
         */
        hasMeta: {
            type: Boolean,
            default: true,
        },

        /**
         * the language of the date picker
         */
        currentLanguageCode: {
            type: String,
            default: "de",
        },

        /**
         * Configuration Object for Meta Messages with
         * following attributes:
         * {
         *      ! errorMessage?: string;
         *      ! infoMessage?: string;
         *      ! saveMessage?: string;
         * }
         */
        inputMeta: {
            type: Object as () => InputMetaInterface,
            default: {} as InputMetaInterface,
        },

        /**
         * Configuration Object for Input Style with
         * following attributes:
         * {
         *      ! labelColor?: string;
         *      ! backgroundColor?: string;
         *      ! backgroundFocusColor?: string;
         *      ! backgroundHoverColor?: string;
         *      ! inputTextColor?: string;
         *      ! errorColor?: string;
         *      ! saveColor?: string;
         *      ! infoColor?: string;
         * }
         */
        inputStyle: {
            type: Object as () => InputStyleInterface,
            default: {} as InputStyleInterface,
        },
        /**
         * tabindex for the input
         */
        tabIndex: {
            type: Number,
            default: 0,
        },

        dropdownPosition: {
            type: Object as () => DropdownPositionInterface,
            default: {} as DropdownPositionInterface,
        },
    },
    emits: ["change", "update:modelValue"],
    setup(props, ctx) {
        const { isMobileDevice } = useNavigatorOptions(props);
        const { removeErrorMessage } = useHelpersStore();
        const { errorMessages } = storeToRefs(useHelpersStore());

        const dropdownWrapperElement = ref<any>();

        /**
         * this is the value that is getting displayed
         * it depends on the type and modelValue
         */
        const computedValue = computed(() => {
            // ! if it is datetime-local format: YYYY.mm.dd HH:mm
            if (props.modelValue && props.type == "datetime-local") {
                const timeArray = (props.modelValue as string).split("T");
                const dateArray = timeArray[0].split("-");
                return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]} ${timeArray[1]}`;
            }
            // ! if it is date-range format: YYYY.mm.dd - YYYY.mm.dd
            else if (props.modelValue && props.type == "date-range") {
                const dateRange = props.modelValue as DateRangeInterface;
                let dateString = "";
                if (dateRange.start) {
                    const dateArray = dateRange.start.split("-");
                    dateString += `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
                }
                if (dateRange.end) {
                    const dateArray = dateRange.end.split("-");
                    dateString += ` - ${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
                }

                return dateString;
            }
            // ! if it is date format: YYYY.mm.dd
            else if (props.modelValue && props.type == "date") {
                const dateArray = (props.modelValue as string).split("-");
                return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
            }
            return "";
        });

        /**
         * closes the dropdown
         *
         * @returns void
         */
        function closeDropDown(): void {
            dropdownWrapperElement.value?.$refs?.dropdownElement?.closeDropDown();
        }
        /**
         * this function emits the new value
         *
         * @param  {string} date
         * @returns void
         */
        function handleMobileDateChange(date: string): void {
            ctx.emit("update:modelValue", date);
        }
        /**
         * this function formats the value received from the date picker depending
         * on which type is selected (date,datetime-local,date-range)
         *
         * @returns void
         */
        function handleDateChange(): void {
            if (props.type == "date-range") {
                let dateObject: DateRangeInterface = { start: null, end: null };
                const dates = newDate.value as {
                    end: Date;
                    start: Date;
                };
                dateObject.start = getFormatedDate(dates.start);

                dateObject.end = getFormatedDate(dates.end);

                closeDropDown();
                ctx.emit("update:modelValue", dateObject);
                return;
            }
            let dateString = getFormatedDate(newDate.value as Date | null);
            if (props.type == "datetime-local") {
                dateString += "T";
                if (newTime.value) {
                    dateString += newTime.value;
                } else {
                    dateString += "00:00";
                }
            } else if (props.type == "date") {
                closeDropDown();
            }
            removeErrorMessage(props.id);
            ctx.emit(
                "update:modelValue",
                convertToDateInputString(new Date(dateString))
            );
        }
        function convertToDateInputString(date: Date): string {
            return new Intl.DateTimeFormat("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour12: false,
            }).format(date);
        }
        /**
         * adds a time to the old modelvalue and emits the result
         *
         * @returns void
         */
        function handleTimeChange(): void {
            let dateString = getFormatedDate(newDate.value as Date | null);
            dateString += `T${newTime.value}`;
            ctx.emit("update:modelValue", dateString);
        }
        /**
         * formats a Date to yyyy-MM-dd if no date is selected,
         * the result is the current date
         *
         * @param  {Date|null} date
         * @returns string
         */
        function getFormatedDate(date: Date | null): string {
            if (date) {
                const year = date!.getFullYear();
                const month = date!.getMonth() + 1;
                const day = date!.getDate();
                return `${year}-${month}-${day}`;
            } else {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth() + 1;
                const day = today.getDate();
                return `${year}-${month}-${day}`;
            }
        }

        const newDate = ref<
            { end: null | Date; start: null | Date } | Date | null
        >(null);
        const newTime = ref<string | null>(null);
        /**
         * this function fills in the newdate and newtime ref
         *
         * @returns void
         */

        function formatDate(): void {
            if (props.type == "date-range") {
                let dateRange: { start: null | Date; end: null | Date } = {
                    start: null,
                    end: null,
                };
                // {start:yyyy-MM-dd, end:yyyy-MM-dd}

                if (props.modelValue) {
                    const currentRange = props.modelValue as DateRangeInterface;
                    if (currentRange.start) {
                        dateRange.start = new Date(currentRange.start);
                    }
                    if (currentRange.end) {
                        dateRange.end = new Date(currentRange.end);
                    }
                }
                newDate.value = dateRange;
                return;
            }

            let date: string | undefined = "";
            if (props.type == "datetime-local") {
                date = (props.modelValue as string)?.split("T")[0];
            } else {
                date = props.modelValue as string;
            }
            if (date && date != "") {
                newDate.value = new Date(date);
            }

            if (props.type == "datetime-local") {
                const dateArray = (props.modelValue as string)?.split("T");
                if (dateArray && dateArray.length == 2) {
                    newTime.value = dateArray[1];
                }
            }
        }
        const metaInformation = computed(() => {
            let metaInfos: MetaInformationInterface = {};
            errorMessages.value.forEach((error: MetaInformationInterface) => {
                if (
                    (error.name == props.id || error.name == props.name) &&
                    error.value
                ) {
                    metaInfos.errorMessage = error.value;
                }
            });
            if (
                !metaInfos.errorMessage &&
                !metaInfos.infoMessage &&
                !metaInfos.saveMessage
            ) {
                return null;
            } else {
                return metaInfos;
            }
        });

        watch(
            () => props.modelValue,
            () => {
                if (props.modelValue) {
                    formatDate();
                } else {
                    newDate.value = null;
                    newTime.value = null;
                }
            }
        );
        return {
            dropdownWrapperElement,
            handleMobileDateChange,
            handleDateChange,
            handleTimeChange,
            isMobileDevice,
            newDate,
            newTime,
            closeDropDown,
            computedValue,
            metaInformation,
        };
    },
});
