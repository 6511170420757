import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-2 pl-8 w-full" }
const _hoisted_2 = { class: "flex w-full justify-between" }
const _hoisted_3 = { class: "text-md" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_plus = _resolveComponent("icon-plus")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_search = _resolveComponent("input-search")!
  const _component_policy_container = _resolveComponent("policy-container")!
  const _component_add_state_modal = _resolveComponent("add-state-modal")!
  const _component_delete_modal = _resolveComponent("delete-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("settings.state")), 1),
      _createVNode(_component_custom_button, {
        class: "w-auto h-10",
        id: 'add-state-button',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAddState = true))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_icon_plus, {
              color: 'white',
              class: "mr-3"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("settings.state")), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_input_search, {
      class: "w-64 mt-5",
      id: 'search-state',
      name: 'search-state',
      modelValue: _ctx.stateSearch,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stateSearch) = $event)),
      placeholder: _ctx.$t('search')
    }, null, 8, ["modelValue", "placeholder"]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredStates, (singleState) => {
        return (_openBlock(), _createBlock(_component_policy_container, {
          key: singleState.id,
          item: singleState,
          onEdit: ($event: any) => ((_ctx.toEditData = singleState), (_ctx.openAddState = true)),
          onDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openDeleteModal = true))
        }, null, 8, ["item", "onEdit"]))
      }), 128))
    ]),
    _createVNode(_component_add_state_modal, {
      open: _ctx.openAddState,
      type: 'state',
      editData: _ctx.toEditData,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openAddState = false))
    }, null, 8, ["open", "editData"]),
    _createVNode(_component_delete_modal, {
      open: _ctx.openDeleteModal,
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openDeleteModal = false)),
      type: 'Status'
    }, null, 8, ["open"])
  ]))
}