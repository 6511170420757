import ApiService from "@/common/api_service";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { BaseDataStoreStateInterface } from "../interfaces";
import { BaseDataEditInterface, BaseDataTypeEnum } from "./interfaces";

export const useBaseDataStore = defineStore({
    id: "baseDataStore",
    state: (): BaseDataStoreStateInterface => ({
        policies: [],
        types: [],
        states: [],
        baseDataType: BaseDataTypeEnum.POLICY,
    }),
    getters: {},
    actions: {
        /**
         * UPDATE
         */
        async updateBaseData(
            data: BaseDataEditInterface
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.put({
                resource:
                    this.baseDataType == BaseDataTypeEnum.POLICY
                        ? "norm-policies"
                        : "norm-types",
                slug: data.id,
                params: data,
            });

            if (response?.status == 200) {
                if (this.baseDataType == "policy") {
                    const policyIndex = this.policies.findIndex(
                        (item) => item.id == data.id
                    );
                    this.policies[policyIndex] = response?.data.data;
                } else {
                    const typeIndex = this.types.findIndex(
                        (item) => item.id == data.id
                    );
                    this.types[typeIndex] = response?.data.data;
                }
            }
            return response;
        },

        /**
         * CREATE
         */
        async createBaseData(
            data: BaseDataEditInterface
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.post({
                resource:
                    this.baseDataType == "policy"
                        ? "norm-policies"
                        : "norm-types",
                params: data,
            });
            if (response?.status == 201) {
                if (this.baseDataType == "policy") {
                    this.policies.push(response?.data.data);
                } else {
                    this.types.push(response?.data.data);
                }
            }
            return response;
        },

        /**
         * DELETE
         */
        async deleteBaseData(
            data: BaseDataEditInterface
        ): Promise<AxiosResponse | null> {
            const response = await ApiService.delete({
                resource:
                    this.baseDataType == "policy"
                        ? "norm-policies/" + data.id
                        : "norm-types/" + data.id,
            });
            if (response?.status == 200) {
                if (this.baseDataType == "policy") {
                    const policyIndex = this.policies.findIndex(
                        (item) => item.id == data.id
                    );
                    this.policies.splice(policyIndex, 1);
                } else {
                    const typeIndex = this.types.findIndex(
                        (item) => item.id == data.id
                    );
                    this.types.splice(typeIndex, 1);
                }
            }
            return response;
        },

        /**
         * FETCH
         */
        async fetchBaseDataPolicies(): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "norm-policies",
            });
            this.policies = response?.data.data;
            return response;
        },

        async fetchBaseDataTypes(): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "norm-types",
            });
            this.types = response?.data.data;
            return response;
        },

        async fetchBaseDataStatus(): Promise<AxiosResponse | null> {
            const response = await ApiService.get({
                resource: "norm-statuses",
            });
            this.states = response?.data.data;
            return response;
        },
    },
});
