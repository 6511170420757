export interface EmailContentInterface {
    subject?: string;
    header?: string;
    footer?: string;
}
export interface UserInterface {
    id?: number;
    first_name?: string;
    last_name?: string;
    full_name?: string;
    email?: string;
    roles?: RolesInterface[];
    role_ids?: number[];
    avatar_url?: string;
    is_verified?: boolean;
}
export interface EmailTypeInterface {
    id?: number;
    name?: string;
    description?: string;
    email_content?: string;
}
export enum BaseDataTypeEnum {
    POLICY = "policy",
    TYPE = "type",
}
export interface BaseDataInterface {
    id: number;
    short_name?: string;
    name?: string;
    addition?: string;
    date?: string;
}
export interface BaseDataEditInterface {
    id?: number;
    short_name?: string;
    name?: string;
    addition?: string;
    date?: string;
}
export interface RolesInterface {
    id?: number;
    name?: string;
    description?: string | null;
    text?: string;
    permission_ids?: number[];
}

export interface PermissionsInterface {
    id: number;
    name?: string;
    description?: string;
}
