
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import InputSearch from "../../../../../components/inputs/inputSearch/InputSearch.vue";
import CustomButton from "../../../../../components/inputs/customButton/CustomButton.vue";
import IconPlus from "../../../../../components/icons/IconPlus.vue";
import PolicyContainer from "../../../containers/DataContainer.vue";
import AddPolicyModal from "./modals/addGuidelineModal.vue";
import DeleteModal from "../../../modals/ConfirmDeleteModal.vue";
import loadingAnimation from "@/components/helpers/loadingAnimation/LoadingAnimation.vue";
import { storeToRefs } from "pinia";
import {
    BaseDataEditInterface,
    BaseDataInterface,
    BaseDataTypeEnum,
} from "@/store/settings/interfaces";
import { useHelpersStore } from "@/store/helpers";
import { ToastMessageType } from "@/components/helpers/theToastMessage/toast_message_helpers";
import { useBaseDataStore } from "@/store/settings/baseDataStore";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "baseDataPolicies",
    components: {
        InputSearch,
        CustomButton,
        IconPlus,
        PolicyContainer,
        AddPolicyModal,
        DeleteModal,
        loadingAnimation,
    },
    setup() {
        const { policies, baseDataType } = storeToRefs(useBaseDataStore());
        const { fetchBaseDataPolicies, deleteBaseData } = useBaseDataStore();
        const { toastMessageText } = storeToRefs(useHelpersStore());
        const { setLoadingId, removeLoadingId } = useHelpersStore();
        const openAddPolicy = ref<boolean>(false);
        const openDeleteModal = ref<boolean>(false);
        const policySearch = ref<string>("");
        const toEditData = ref<BaseDataEditInterface>({});
        const state = reactive({});
        const policy = ref<BaseDataTypeEnum>(BaseDataTypeEnum.POLICY);
        const router = useRouter();

        const filteredPolicies = computed(() => {
            return policies.value.filter(
                (policy: BaseDataInterface) =>
                    policy.short_name
                        ?.toLowerCase()
                        .includes(policySearch.value.toLowerCase()) ||
                    policy.name
                        ?.toLowerCase()
                        .includes(policySearch.value.toLowerCase())
            );
        });

        async function deleteData(): Promise<void> {
            setLoadingId("confirm-delete-button");
            await deleteBaseData(toEditData.value);
            removeLoadingId("confirm-delete-button");
            toastMessageText.value = [
                {
                    type: "success" as ToastMessageType,
                    message: "Richtline wurde erfolgreich gelöscht.",
                },
            ];
            openDeleteModal.value = false;
            openAddPolicy.value = false;
            toEditData.value = {};
        }

        function openEdit(policy: BaseDataInterface): void {
            toEditData.value = policy;
            openAddPolicy.value = true;
            baseDataType.value = BaseDataTypeEnum.POLICY;
        }

        function openAdd(): void {
            openAddPolicy.value = true;
            baseDataType.value = BaseDataTypeEnum.POLICY;
        }
        const isLoading = ref(false);
        onMounted(async () => {
            policies.value = [];
            isLoading.value = true;
            const response = await fetchBaseDataPolicies();
            isLoading.value = false;
            if (response?.status != 200) {
                router.push({ name: "Settings" });
            }
        });
        return {
            state,
            openAddPolicy,
            openDeleteModal,
            toEditData,
            filteredPolicies,
            policySearch,
            deleteData,
            policies,
            policy,
            openEdit,
            openAdd,
            isLoading,
        };
    },
});
