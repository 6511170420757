import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-2 pl-8 w-full h-full flex flex-col flex-shrink-0" }
const _hoisted_2 = { class: "md:flex w-full justify-between mb-5" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  key: 0,
  class: "h-1/2 w-full flex items-center justify-center"
}
const _hoisted_5 = {
  key: 1,
  class: "flex justify-center items-center w-full h-full text-grey-darker"
}
const _hoisted_6 = {
  key: 2,
  class: "mt-5 overflow-y-auto pr-1 h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_plus = _resolveComponent("icon-plus")!
  const _component_custom_button = _resolveComponent("custom-button")!
  const _component_input_search = _resolveComponent("input-search")!
  const _component_loading_animation = _resolveComponent("loading-animation")!
  const _component_policy_container = _resolveComponent("policy-container")!
  const _component_add_policy_modal = _resolveComponent("add-policy-modal")!
  const _component_delete_modal = _resolveComponent("delete-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t("settings.types")), 1),
      _createVNode(_component_custom_button, {
        class: "md:w-auto w-full h-10 md:mt-0 mt-5",
        id: 'add-type-button',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAdd()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_icon_plus, {
              color: 'white',
              class: "mr-1"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("settings.type")), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_input_search, {
      class: "md:w-64 w-full mb-5",
      id: 'search-type',
      name: 'search-type',
      modelValue: _ctx.typesSearch,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.typesSearch) = $event)),
      placeholder: _ctx.$t('search')
    }, null, 8, ["modelValue", "placeholder"]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_loading_animation, {
            id: 'loadingAnimation',
            name: 'loadingAnimation',
            animationStyle: { radius: '12', animationColor: 'grey' }
          })
        ]))
      : (_ctx.filteredTypes.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("no_search_result")), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTypes, (type) => {
              return (_openBlock(), _createBlock(_component_policy_container, {
                key: type.id,
                item: type,
                onEdit: ($event: any) => (_ctx.openEdit(type))
              }, null, 8, ["item", "onEdit"]))
            }), 128))
          ])),
    _createVNode(_component_add_policy_modal, {
      open: _ctx.openAddType,
      type: 'type',
      editData: _ctx.toEditData,
      onClose: _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openAddType = false), (_ctx.toEditData = {}))),
      onDelete: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openDeleteModal = true))
    }, null, 8, ["open", "editData"]),
    _createVNode(_component_delete_modal, {
      open: _ctx.openDeleteModal,
      onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openDeleteModal = false)),
      onDelete: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteData())),
      type: 'Art'
    }, null, 8, ["open"])
  ]))
}