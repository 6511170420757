
import { defineComponent } from "vue";
import Policies from "./views/Guidelines.vue";
import Types from "./views/Types.vue";
import State from "./views/State.vue";

export default defineComponent({
    name: "baseData",
    components: { Policies, State, Types },
    setup() {
        return {};
    },
});
